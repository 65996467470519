import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import moment from 'moment';
import 'firebase/analytics';
import 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyAChIgyXQVBZPnv-I97JvrdgB4Wbp4Jo1I",
  authDomain: "hlpt-support-widget.firebaseapp.com",
  projectId: "hlpt-support-widget",
  storageBucket: "hlpt-support-widget.appspot.com",
  messagingSenderId: "239816340610",
  appId: "1:239816340610:web:66574498b8a8e49963f09b",
  measurementId: "G-Y13VL5NEKF"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init firestore service
const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const projectStorage = firebase.storage()
const functions = firebase.functions()
const analytics = firebase.analytics()




// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

// export firestore
export { projectFirestore, projectAuth, projectStorage, timestamp, functions}
