import { ref } from 'vue';
import { projectAuth } from '../firebase/config';
import router from '../router';
import firebase from 'firebase/app'
import 'firebase/auth'

const error = ref(null)
const passwordResetLink = async (email) => {
    error.value = null
    await firebase.auth().sendPasswordResetEmail(email).then(() => {
        error.value = null
    }).catch((err) => {
        console.log(err.message)
        error.value = 'Error while sending link'
    });
}

const sendPasswordResetLink = () => {
  return { error, passwordResetLink};
}

export default sendPasswordResetLink