<template>
    <div class="container">
        <ArgonSnackbar :isOpen="isError.error || isSuccess.success" :title="isError.error ? isError.msg : isSuccess.msg"
        :color="isError.error ? 'danger' : 'success'" :timeOut='10000' @close="isError.error = false; isSuccess.success = false" />
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 mt-2">
                <!-- <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5> -->
                <form role="form" @submit.prevent="handleSubmit" class="text-start">
                    <div class="mb-1">
                        <input id="email" type="email" class="form-control" placeholder="Email" v-model="email"
                            required />
                    </div>

                    <div class="error text-sm text-danger">{{ error }}</div>

                    <div class="text-center">
                        <argon-button color="success" variant="gradient" full-width class="my-4 mb-2" :disabled="email == ''">
                            <span v-if="isSigningIn">
                                Sending... <i class="fa fa-circle-o-notch fa-spin"></i>
                            </span>
                            <span v-else>
                                Send Password Reset Link
                            </span>
                        </argon-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref } from 'vue'
import sendPasswordResetLink from '@/composables/sendPasswordResetLink'
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from 'vue-router'
import ArgonSnackbar from '@/components/ArgonSnackbar.vue';
import moment from 'moment';

export default {
    components: {
        ArgonButton,
        ArgonSnackbar
    },
    setup(props, context) {
        // refs
        const isSigningIn = ref(false)
        const email = ref('')
        const password = ref('')
        const router = useRouter();
        const isError = ref({
            error: false,
            msg: ''
        })
        const isSuccess = ref({
            success: false,
            msg: ''
        })
        

        const { error, passwordResetLink } = sendPasswordResetLink()

        const handleSubmit = async () => {
            isError.value.error = false
            isError.value.msg = '';

            isSuccess.value.success = false
            isSuccess.value.msg = '';

            isSigningIn.value = true
            await passwordResetLink(email.value)
            if (!error.value) {
                isSuccess.value.success = true
                isSuccess.value.msg = 'Reset link sent successfully';

                //   router.push({ name: 'Content' })
                email.value = ''
            } else {
                isError.value.error = true
                isError.value.msg = error.value;
            }
            isSigningIn.value = false
        }

        return { email, password, handleSubmit, error, isSigningIn, isError, isSuccess}
    }
}
</script>