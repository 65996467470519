<template>
  <div class="home-selected-item pt-5">
    <div></div>
    <p>{{ text?.answer }}</p>
    <button v-if="!isSubmit" @click="$emit('onBack')">
      <i class="fa fa-times"></i>
      Change answer
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: Object,
      default: '',
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onBack'],
};
</script>

<style lang="scss" scoped>
.home-selected-item {
  font-family: 'proxima-nova', Arial,sans-serif;
  margin-left: -2.2rem;
  background: #f8f8f8;
  padding: 2em 2.2em 2.4em;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    background: #e6e6e6;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  > div {
    width: 14px;
    height: 14px;
    position: relative;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #e6e6e6;
    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      border-radius: 50%;
      background-color: #000;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  > p {
    font-size: 0.9375rem;
    line-height: 1.032rem;
    display: inline-block;
    margin-bottom: 0;
    flex: 1;
  }
  > button {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    color: #999;
    font-size: 0.75rem;
    border-bottom: 2px solid transparent;
    font-weight: 700;
    &:hover {
      color: #000;
      border-color: #000;
    }
  }
}
</style>
