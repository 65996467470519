import { ref } from "vue"
import { projectFirestore } from "../../firebase/config";
import { useStore } from "vuex";


const fgetAllNewsFlash = () => {
    const store = useStore()
    const hlpt_support_library_id = store.state.parent_lib_id;
    const newsFlash = ref([])
    const categories = ref([])
    const getNewsFlash = async (library_key, limit) => {
        let default_disabled_newsFlash = []
        let disabled_default_categories  = []
        
        let support_lib_ids = []
        if(hlpt_support_library_id) {
            support_lib_ids = [library_key,hlpt_support_library_id]
        } else {
            support_lib_ids = [library_key]
        }
        await projectFirestore.collection('Categories').onSnapshot( async(snap) => {
            if(!snap.empty) {
                categories.value = snap.docs.map((el) => { return {...el.data(), id:el.id} })
            }
        await projectFirestore.collection('SupportLibraries').doc(library_key).get().then(async(doc) => {
            if(doc.data()?.disabled_default_newsFlash) {
                default_disabled_newsFlash = doc.data().disabled_default_newsFlash
            } 
            if(doc.data()?.disabled_default_categories) {
                disabled_default_categories = doc.data().disabled_default_categories
            }
            if(limit !== undefined) {
                await projectFirestore.collection('NewsFlash').where('support_lib_id', 'in', support_lib_ids).where('is_published', '==', true).orderBy('timeStamp', 'desc').onSnapshot(async(snap) => {
                    if(!snap.empty) {
                        snap.docs.forEach((el) => { 
                            if(!disabled_default_categories.includes(el.data().newsFlash_categories) && !default_disabled_newsFlash.includes(el.id) && newsFlash.value.length < limit) {
                                newsFlash.value.push({...el.data(), categoryName:categories.value?.filter((ele) => ele.id == el.data()?.newsFlash_categories)[0]?.category_title, id:el.id} )
                            }
                        })
                    } 
                })
            } else {
                await projectFirestore.collection('NewsFlash').where('support_lib_id', 'in', support_lib_ids).where('is_published', '==', true).orderBy('timeStamp', 'desc').onSnapshot(async(snap) => {
                    if(!snap.empty) {
                        snap.docs.forEach((el) => { 
                            if(!disabled_default_categories.includes(el.data().newsFlash_categories) && !default_disabled_newsFlash.includes(el.id)) {
                                newsFlash.value.push({...el.data(), categoryName:categories.value?.filter((ele) => ele.id == el.data()?.newsFlash_categories)[0]?.category_title, id:el.id} )
                            }
                        })
                    } 
                })
            }
           
        })
    })
    }

    return {
        getNewsFlash,
        newsFlash
    }
}

export default fgetAllNewsFlash