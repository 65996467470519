const axios = require("axios");
// import router from "@/router";
let baseURL = process.env.VUE_APP_API_BASE_URL;
let inboxBaseURL = process.env.VUE_APP_INBOX_API_BASE_URL;
let integrationBaseURL = process.env.VUE_APP_INTEGRATION_API_BASE_URL;
let teamsBaseURL = process.env.VUE_APP_TEAMS_API_URL
let aiBaseURL = process.env.VUE_APP_AI_API_BASE_URL
let logEventsBaseUrl =  process.env.VUE_APP_EVENT_HISTORY_URL
// import * as sweetalert from "sweetalert";
import useLogout from '@/composables/useLogout';
import moment from 'moment';

const logEventsInstance = axios.create({
    baseURL: logEventsBaseUrl,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});


const instance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});

const aiIstance = axios.create({
    baseURL: aiBaseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});


const inboxInstance = axios.create({
    baseURL: inboxBaseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});

const inboxWidgetInstance = axios.create({
    baseURL: inboxBaseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});

const intergrationInstance = axios.create({
    baseURL: integrationBaseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});

const teamsInstance = axios.create({
    baseURL:teamsBaseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
})

// Create a separate instance for authentication-related API calls
const authInstance = axios.create({
    baseURL: inboxBaseURL, // Replace with your base URL
});


instance.interceptors.request.use(async(config) => {
    const token = localStorage.getItem('inbox-chat-token');
    let tokenTime = localStorage.getItem('token-time');
    if (tokenTime) {
        const now = moment.utc();
        let storedTime = moment.utc(+tokenTime).valueOf();

        // Calculate the difference in minutes (or hours, if needed)
        let diffInHours = now.diff(storedTime, 'hours');
        // Check if more than 23 minutes have passed
        if (diffInHours >= 23) {
            // console.log("23 hours have passed since the given time.");

            let payload = {
                email: localStorage.getItem('user_email'),
                user_id: localStorage.getItem('user_id')
            };

            // Use the separate authInstance to avoid circular dependency
            try {
                const res = await authInstance.post('/login', payload);

                // Store the new token and update token time
                if(res.data.token) {
                    localStorage.setItem('inbox-chat-token', res.data.token); // Assuming response has token
                    let nowTimeStamp = moment.utc().valueOf().toString();
                    localStorage.setItem('token-time', nowTimeStamp);
                }
            } catch (err) {
                console.log('Error while refetching token', err);
            }
        } else {
            // console.log("Less than 23 hours have passed.");
        }
    }
    
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    config.validateStatus = (status) => status < 400;
    return config;
});

inboxInstance.interceptors.request.use(async(config) => {
    const token = localStorage.getItem('inbox-chat-token');
    let tokenTime = localStorage.getItem('token-time');
    if (tokenTime) {
        const now = moment.utc();
        let storedTime = moment.utc(+tokenTime).valueOf();

        // Calculate the difference in hours (or hours, if needed)
        let diffInHours = now.diff(storedTime, 'hours');
        // Check if more than 23 hours have passed
        if (diffInHours >= 23) {
            // console.log("23 hours have passed since the given time.");

            let payload = {
                email: localStorage.getItem('user_email'),
                user_id: localStorage.getItem('user_id')
            };

            // Use the separate authInstance to avoid circular dependency
            try {
                const res = await authInstance.post('/login', payload);

                // Store the new token and update token time
                if(res.data.token) {
                    localStorage.setItem('inbox-chat-token', res.data.token); // Assuming response has token
                    let nowTimeStamp = moment.utc().valueOf().toString();
                    localStorage.setItem('token-time', nowTimeStamp);
                }
            } catch (err) {
                console.log('Error while refetching token', err);
            }
        } else {
            // console.log("Less than 23 hours have passed.");
        }
    }

    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    config.validateStatus = (status) => status < 400;
    return config;
});

inboxInstance.interceptors.response.use(
    (successRes) => {
       
        // console.log('successRes', successRes)
        return successRes;
    },
    (error) => {
        // console.log('error', error)
        // sweetalert({
        //     title: "Error!",
        //     text: error?.response?.data?.message
        //         ? error?.response?.data?.message
        //         : "An error occurred",
        //     icon: "error",
        //     buttons: {
        //         confirm: {
        //             text: "Close",
        //             value: true,
        //             visible: true,
        //             className: "btn bg-gradient-danger mx-auto",
        //             closeModal: true,
        //         },
        //     },
        // });
        console.log("caught error", error?.response?.status);
        const { logout } = useLogout();
        if(error?.response?.status == 401) {
            logout()
        }
        return Promise.reject(error);
    }
);


const Api = {
    getSopCategories: async (support_lib_id) => {
        return await instance.get(`sop_categories?support_lib_id=${support_lib_id}`);
    },
    getSopArticlesbackend:async (params) => {
        let url = `sop_articles?support_lib_id=${params.support_lib_id}&rut=admin&published=all`
        if(params.category_id) {
            url += `&category_id=${params.category_id}`
        }
        if(params.article_id) {
            url += `&article_id=${params.article_id}`
        }
        if(params.per_page) {
            url += `&per_page=${params.per_page}`
        }
        if(params.first_document_id) {
            url += `&per_page=${params.first_document_id}`
        }
        if(params.last_document_id) {
            url += `&per_page=${params.last_document_id}`
        }
        return await instance.get(url);
    },
    getCoursesLessonsbackend:async (params) => {
        let url = `courses_lessons?support_lib_id=${params.support_lib_id}&rut=admin&published=all`
        if(params.course_id) {
            url += `&course_id=${params.course_id}`
        }
        if(params.lesson_id) {
            url += `&lesson_id=${params.lesson_id}`
        }
        return await instance.get(url);
    },
    getCoursesLessonsFrontend:async (params) => {
        let url = `courses_lessons?support_lib_id=${params.support_lib_id}`
        if(params.course_id) {
            url += `&course_id=${params.course_id}`
        }
        if(params.lesson_id) {
            url += `&lesson_id=${params.lesson_id}`
        }
        return await instance.get(url);
    },
    getCourses: async (params) => {
        let url = `courses?support_lib_id=${params.support_lib_id}&rut=admin&published=all`
        if(params.library_type) {
            url += `&library_type=${params.library_type}`
        }
        return await instance.get(url);
    },
    getSubscription: async () => {
        return await instance.get('subscriptions')
    },
    getNotifications:async (params) => {
        return await inboxInstance.get(`/agent_notifications?support_lib_id=${params.support_lib_id}`)
    },
    updateNotifications:async (payload) => {
        return await inboxInstance.put(`/agent_notifications`, payload)
    },
    login:async(payload) => {
        return await inboxInstance.post(`/login`, payload)
    },

    regenerateToken:async() => {
        let payload  = {
            email:localStorage.getItem('user_email'),
            user_id:localStorage.getItem('user_id')
        }
        try {
            let res =  await axios.post(`${process.env.VUE_APP_INBOX_API_BASE_URL}/login`, payload)
            if(res?.data?.token) {
                localStorage.setItem('inbox-chat-token', res.data.token)
            }
        } 
        catch(error)  {
            console.log('payload error', error)
        }   
    },

    getNotificationSubscription:async(params) => {
        let url = `/notification_subscriptions?support_lib_id=${params.support_lib_id}`;
        if(params.include_parent_lib_id) {
            url += `&include_parent_lib_id=${params.include_parent_lib_id}`
        }
        return await inboxInstance.get(url)
    },

    submitNewNotificationSubscription:async(payload) => {
        return await inboxInstance.post('/notification_subscriptions', payload)
    },

    updateNotificationSubscription:async(params, payload) => {
        let url = `/notification_subscriptions?support_lib_id=${params.support_lib_id}`;
        return await inboxInstance.put(url, payload)
    },

    deleteNotificationSubscription:async(payload) => {
        return await inboxInstance.delete(`/notification_subscriptions`, {data:payload})
    },


    updateAgentInformation:async (payload) => {
        return await inboxInstance.put(`/user`, payload)
    },
    getAgentList:async (params) => {
        let url = `/agents?support_lib_id=${params.support_lib_id}`
        if(params.parent_lib_id) {
            url += `&parent_lib_id=${params.parent_lib_id}&include_parent_lib_id=1`
        }
        if(params.rt) {
            url += `&rt=${params.rt}`
        }
        if(params.mode) {
            url += `&mode=${params.mode}`
        }
        return await inboxInstance.get(url);
    },
    getAgentListForWidget:async (params) => {
        let url = `/agents?support_lib_id=${params.support_lib_id}`
        if(params.parent_lib_id) {
            url += `&parent_lib_id=${params.parent_lib_id}&include_parent_lib_id=1`
        }
        if(params.rt) {
            url += `&rt=${params.rt}`
        }
        if(params.mode) {
            url += `&mode=${params.mode}`
        }
        return await inboxWidgetInstance.get(url);
    },
    getLoggedInUser:async () => {
        return await inboxInstance.get(`/user`);
    },
    deleteAgentProfileImage:async(payload) => {
        return await inboxInstance.put(`user`, payload)
    },
    getAgencyDetails:async(support_lib_id) => {
        return await inboxInstance.get(`agency?support_lib_id=${support_lib_id}`)
    },
    updateAgencyAction:async(payload) => {
        const token = localStorage.getItem('inbox-chat-token');
        const headers  = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
        }
        return await inboxInstance.patch(`agency` , payload, {headers:headers})
    },
    submitAgencyDetails:async(payload) => {
        const token = localStorage.getItem('inbox-chat-token');
        const headers  = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
        }
        return await inboxInstance.post(`agency` , payload, {headers:headers})
    },
    updateAgencyDetails:async (payload) => {
        const token = localStorage.getItem('inbox-chat-token');
        const headers  = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
        }
        return await inboxInstance.put(`agency` , payload, {headers:headers})
    },
    
    getTicketStatuses:async(support_lib_id) => {
        return await inboxInstance.get(`ticket_statuses?support_lib_id=${support_lib_id}`)
    },
    updateTicketStatus:async(payload) => {
        return await inboxInstance.patch(`tickets`, payload)
    },
    listTicketCategories:async(support_lib_id) => {
        return await inboxInstance.get(`ticket_categories?support_lib_id=${support_lib_id}`)
    },
    submitNewTicketCategory:async(payload) => {
        return await inboxInstance.post(`ticket_categories`, payload)
    },
    updateTicketCategory:async(payload, ticket_category_ref) => {
        return await inboxInstance.put(`ticket_categories?ticket_category_ref=${ticket_category_ref}`, payload)
    },
    listTicketTiers:async(support_lib_id) => {
        return await inboxInstance.get(`ticket_tiers?support_lib_id=${support_lib_id}`)
    },
    submitNewTicketTier:async(payload) => {
        return await inboxInstance.post(`ticket_tiers`, payload)
    },
    updateTicketTier:async(payload, ticket_tier_ref) => {
        return await inboxInstance.put(`ticket_tiers?ticket_tier_ref=${ticket_tier_ref}`, payload)
    },
    removeGoogleIntegration:async(payload) => {
        return await intergrationInstance.delete(`google/auth`, {data:payload})
    },
    removeMailgunIntegration:async(payload) => {
        return await intergrationInstance.delete(`mailgun/auth`, {data:payload})
    },
    updateTicketCategoryAndTierStatus:async(payload) => {
        return await inboxInstance.patch(`tickets`, payload)
    },
    searchCoPilot:async(payload) => {
        return await aiIstance.post(`search`, payload)
    },
    listChildLibraries:async(params) => {
        return await inboxInstance.get(`agency?support_lib_id=${params.support_lib_id}&list_children=1`)
    },
    getWorkflows:async (params) => {
        let url = `workflows?support_lib_id=${params.support_lib_id}`;
        if(params?.workflow_ref) {
            url += `&workflow_ref=${params?.workflow_ref}`
        }
        if(params?.is_enabled !== undefined) {
            url += `&is_enabled=${params?.is_enabled}`
        }
        if(params?.q) {
            url += `&q=${params.q}`
        }
        if(params?.per_page) {
            url += `&per_page=${params.per_page}`
        }
        if(params?.page) {
            url += `&page=${params.page}`
        }
        if(params?.order_by) {
            url += `&order_by=${params.order_by}`
        }
        if(params?.order) {
            url += `&order=${params.order}`
        }
        if(params?.workflow_folder_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        return await inboxInstance.get(url)
    },
    createWorkflow:async (payload) => {
        return await inboxInstance.post(`workflows`, payload)
    },
    updateWorkflow:async(payload, params) => {
        return await inboxInstance.put(`workflows?workflow_ref=${params.workflow_ref}`, payload)
    },
    deleteWorkflow:async(payload) => {
        return await inboxInstance.delete(`workflows`, {data:payload})
    },
    getWorkflowFolders:async (params) => {
        let url = `workflow_folders?support_lib_id=${params.support_lib_id}`;
        if(params?.workflow_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        if(params?.workflow_folder_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        return await inboxInstance.get(url)
    },
    createWorkflowFolder:async (payload) => {
        return await inboxInstance.post(`workflow_folders`, payload)
    },
    updateWorkflowFolder:async(payload, params) => {
        return await inboxInstance.put(`workflow_folders?workflow_folder_ref=${params.workflow_folder_ref}`, payload)
    },
    deleteWorkflowFolder:async(payload) => {
        return await inboxInstance.delete(`workflow_folders`, {data:payload})
    },
    getModalWidgetDetails:async (params) => {
        return await inboxInstance.get(`modal_widgets?support_lib_id=${params.support_lib_id}`)
    },
    getModalWidgetDetails:async (params) => {
        return await inboxInstance.get(`modal_widgets?support_lib_id=${params.support_lib_id}`)
    },
    getModalWidgetDetailsForWidget:async (params) => {
        return await inboxWidgetInstance.get(`modal_widgets?support_lib_id=${params.support_lib_id}`)
    },
    fetchTags:async (params) => {
        let url = `conv_tags?support_lib_id=${params.support_lib_id}`
        if(params?.conv_ref) {
            url += `&conv_ref=${params.conv_ref}`
        }
        if(params?.show_all) {
             url += `&show_all=${params.show_all}`
        }
        return await inboxInstance.get(url)
    },
    addTags:async(payload) => {
        return await inboxInstance.post(`conv_tags`, payload)
    },

    getPermissions:async(params) => {
        return await teamsInstance.get(`permissions/${params.ref_uid}/${params.role}`)
    },

    updatePermissions:async(payload, ref_uid) => {
        return await teamsInstance.put(`updatepermissions/${ref_uid}`, payload) 
    },

    getOrganizationRoles:async(support_lib_id) => {
        return await teamsInstance.get(`roles/${support_lib_id}`)
    },

    getLogEvents:async(params) => {
        let url = `EventHistory`;
        if(params?.workflow_ref) {
            url += `/${params?.workflow_ref}/1`;
        }
        if(params?.threadId) {
            url += `/${params?.threadId}/2`;
        }
        return await logEventsInstance.get(url)
    },

    getCoBrowsingData:async(params, payload)=> {
         let url = `cobrowsewatchurl/${params.visitor_ref}`
        return await teamsInstance.post(url, payload)
    },

    ticketCreatedMethods:async(params) => {
        let url = `ticket_methods?support_lib_id=${params.support_lib_id}`
        return await inboxInstance.get(url)
    }
}

export default Api;
