import { ref } from 'vue'
import router from '@/router'
import store from '@/store'
import { projectAuth } from '../firebase/config'

// refs
const error = ref(null)

// logout function
const logout = async () => {
  error.value = null


  try {
    const favicon = document.getElementById("favicon");
    const title = document.getElementById("ogTitle");
    const description = document.getElementById("ogDescription");
    const keywords = document.getElementById("ogKeywords");
    if (favicon) {
      favicon.setAttribute("href", "data:image/x-icon;base64,AA");
    }
    if (title) {
      title.setAttribute("content", " ");
    }
    if (description) {
      description.setAttribute("href", " ");
    }
    if (keywords) {
      keywords.setAttribute("href", " ");
    }
    await projectAuth.signOut();

    localStorage.removeItem('sessionTimeout');
    localStorage.removeItem('support_Lib_id');
    localStorage.removeItem('parent_lib_id');
    localStorage.removeItem('library_domain');
    localStorage.removeItem('user_email');
    localStorage.removeItem("company_name");
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_id');
    localStorage.removeItem('chat_enabled');
    localStorage.removeItem('isConnectionEnabled')
    localStorage.removeItem('agent_connection_queue_ref');
    localStorage.removeItem('agent_connection_device_ref');
    localStorage.removeItem('agent_connection_visitor_ref');
    localStorage.removeItem('agent_connection_agent_ref');
    localStorage.removeItem('agent_connection_agent_con_id');
    localStorage.removeItem('agent_connection_visitor_con_id');
    localStorage.removeItem('agent_connection_conversation_ref');
    localStorage.removeItem('inbox-chat-token');
    localStorage.removeItem('token-time')
    // let reportBugWidget =  document.getElementById('reportBugWidget');
    // let reportBugWidgetBtn = document.getElementsByClassName('videoask-embed__button--SgLKO')[0]
    // if(reportBugWidget) {
    //   reportBugWidget.remove()
    // }
    // if(reportBugWidget) {
    //   reportBugWidgetBtn.remove()
    // }
    // console.log('store.state.parent_lib_id',store.state.parent_lib_id)
    console.log('logout works')
    router.push({ name: 'Login' })
    if (store.state.parent_lib_id) {
      store.state.parent_lib_id = ''
    }


    store.state.support_lib_id = '';
    store.state.user_id = '';
    store.state.agent_picture = '';
    store.state.frontendUnreadMsgs = 0;
    store.state.queueMembers = [];
    store.state.inboxMembers = [];
    store.state.messages = {};
    store.state.selectedChatBefore = null;
    store.state.selectedChat = null;
    store.state.selectedOngoingChat = null;
    store.state.selectedArchive = null;
    store.state.selectedTicket = null;
    store.state.selectedOtherTicket = null;
    store.state.selectedMentionTicket = null;
    store.state.selectedOtherHlptTicket = null;
    store.state.selectedCreatedByYouTicket = null;
    store.state.selectedReassignedTicket = null;
    store.state.selectedUnassignedTicket = null;
    store.state.selectedAwaitingReplyTicket = null;
    store.state.selectedAwaitingReplyHlptTicket = null;
    store.state.socket = '';
    store.state.scrollToBottom = false;
    store.state.display_name = '';
    store.state.isticketCreating = false;
    store.state.isticketTypeCreating = false;
    store.state.isDirectTicketCreating = false;

    store.state.isArchiveSearch = false;
    store.state.searchArchive = false;
    store.state.isArchiveSearching = false;
    store.state.archiveSortByFilter = '';
    store.state.archiveEmailFilter = '';
    store.state.archivePerPage = 20;
    store.state.allChatsSortByFilter = '';
    store.state.archiveChatsTotalRecords = 0;
    store.state.archiveChatsPage = 1;
    store.state.archiveChats = [];
    store.state.inboxChatsType = 'all';
    store.state.isDisplayInboxChats = true;
    store.state.isDisplaySuperviseChats = true;

    store.state.superviseChats = {};
    store.state.allOnGoingChats = [];
    store.state.isSuperviseChatsLoading = true;
    store.state.isAllOnGoingChatsLoading = true;
    store.state.ticketsTotalRecords = 0;
    store.state.ticketsPage = 1;
    store.state.isTicketSearch = false;
    store.state.searchTicket = false;
    store.state.isTicketSearching = false;
    store.state.ticketSortByFilter = '';
    store.state.ticketEmailFilter = '';
    store.state.ticketStatusByFilter = '';
    store.state.ticketCategoryFilter = '';
    store.state.ticketTierFilter = '';
    store.state.ticketCreateMethodFilter = '';
    store.state.ticketRefFilter = '';
    store.state.ticketCreateStatusFilter = '';
    store.state.tickets = [];
    store.state.searchTotalRecords = 0;
    store.state.searchPage = 1;
    store.state.isSearch = false;
    store.state.searchTicket = false;
    store.state.isSearching = false;
    store.state.searchSortByFilter = '';
    store.state.searchEmailFilter = '';
    store.state.searchStatusByFilter = '';
    store.state.searchCategoryFilter = '';
    store.state.searchTagFilter = '';
    store.state.searchTierFilter = '';
    store.state.searchCreateMethodFilter = '';
    store.state.searchTicketRefFilter = '';
    store.state.searchCreateStatusFilter = '';
    store.state.searchRecords = [];
    store.state.searchCompanyFilter = null;
    store.state.searchCtFromFilter = '';
    store.state.searchCtToFilter = '';
    store.state.filterSearchCompanies = '';
    store.state.searchDateFilter = '';
    store.state.searchFilterType = '';
    store.state.searchTicketConversationRefFilter = '';
    store.state.selectedSearch = {};
    store.state.searchQuery = '';
    store.state.childLibraryList = [];

    store.state.isAddingLeadTags = false;
    store.state.tags = [];
    store.state.isLoadingArchieve = false;
    store.state.isLoadingTickets = false;
    store.state.isTicketReplying = false;
    store.state.is_away = null;
    store.state.show_alert = false;
    store.state.awayAgents = [];
    store.state.agentModeUpdated = false;
    
    store.state.userPermissions.canManageGeneralAndSecuritySettings= true;
    store.state.userPermissions.canManageTeammatesSeatsAndPermissions= true;
    store.state.userPermissions.canChangeTeammatesStatus= true;
    store.state.userPermissions.canManageMessengerSettings= true;
    store.state.userPermissions.canManageWidgetSettings= true;
    store.state.userPermissions.canManageTicketSettings= true;
    store.state.userPermissions.canManageChatSettings= true;
    store.state.userPermissions.canCreateAndUpdateHelpCenterArticles= true;
    store.state.userPermissions.canManageAndPublishHelpCenterArticles= true;
    store.state.userPermissions.canCreateAndUpdateNewsArticles= true;
    store.state.userPermissions.canManageAndPublishNewsArticles= true;
    store.state.userPermissions.canCreateAndUpdateCoursesArticles= true;
    store.state.userPermissions.canManageAndPublishCoursesArticles= true;
    store.state.userPermissions.canCreateAndUpdateSopArticles= true;
    store.state.userPermissions.canManageAndPublishSopArticles= true;
    store.state.userPermissions.canTakeChats= true;
    store.state.userPermissions.canSuperviseChats= true;
    store.state.userPermissions.canViewChatArchives= true;
    store.state.userPermissions.canReplyToTickets= true;
    store.state.userPermissions.canViewExistingTickets= true;
    store.state.userPermissions.canCreateAndEditAnAutomation= true;
    store.state.userPermissions.canInviteNewUsers= true;
    store.state.userPermissions.canUpdateUserRoles= true;

    store.state.agent_name = ''

    // console.log('store.state.parent_lib_id',store.state.parent_lib_id)
  }
  catch (err) {
    console.error(err.message)
    error.value = err.message
  }
}

const useLogout = () => {
  return { error, logout }
}

export default useLogout