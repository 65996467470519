import { projectFirestore } from '../firebase/config';
import moment from 'moment';
import { templateSettings } from 'lodash';
const axios = require('axios');

export const sendSupportContactEmail = (
  email,
  companyName,
  companyEmail,
  companyPhone,
  selfManaged,
  searchkeyword,
  message,
  helpText,
  fileUrl = '',
  lastArticleId,
  support_lib_id,
  os,
  browser,
  URL,
  helpSteps,
  customInputs
) => {
  if (searchkeyword !== '') {
    helpText.search = true;
    helpText.searchkeyword = searchkeyword
  }
  let timestamp = moment().valueOf();
  let ticketId = Number(timestamp + String(Math.floor(100 + Math.random() * 900)))
  let to = '';
  let subject = '';
  projectFirestore.collection('SupportLibraries').doc(support_lib_id).get().then(async (snap) => {
    if (snap.exists) {
      // console.log(' snap.data().settings', snap.data().settings)
      let url = `https://auth.locationapi.co/resources1?k=${snap.data().library_refkey}`;
      await axios.get(url).then((res) => {
        if (res.data.lw == 1) {
          if (snap.data().settings && snap.data()?.settings?.ticket?.contactEmail && snap.data()?.settings?.ticket?.contactEmail !== '') {
            to = snap.data().settings.ticket.contactEmail
          } else {
            to = process.env.VUE_APP_SALESFORCE_CONTACT_EMAIL;
          }
        } else {
          if (snap.data().settings && snap.data().settings.ticket.selfManaged == true && snap.data().settings.ticket.contactEmail !== '') {
            to = snap.data().settings.ticket.contactEmail
          } else {
            to = process.env.VUE_APP_SALESFORCE_CONTACT_EMAIL;
          }
        }
      })

      if (snap.data().settings && snap.data().settings.ticket.supportEmailSubject && snap.data().settings.ticket.supportEmailSubject !== '') {
        subject = snap.data().settings.ticket.supportEmailSubject;
        // if (!subject.includes('[companyName]')) {
        //   subject = subject + ' '+ companyName;
        // }
        // if (!subject.includes('[ticketId]')) {
        //   subject += subject + '#'+ ticketId;
        // }
        if (subject.includes('[companyEmail]')) {
          console.log('companyEmail includes')
          subject = subject.replace('[companyEmail]', companyEmail)
        }
        if (subject.includes('[companyPhone]')) {
          console.log('companyPhone includes')
          subject = subject.replace('[companyPhone]', companyPhone)
        }
        if (subject.includes('[accountName]')) {
          console.log('accountName includes')
          subject = subject.replace('[accountName]', companyName)
        }
        if (subject.includes('[userEmail]')) {
          console.log('email includes')
          subject = subject.replace('[userEmail]', email)
        }
        if (subject.includes('[companyName]')) {
          console.log('companyName includes')
          subject = subject.replace('[companyName]', companyName)
        }
        if (subject.includes('[userMessage]')) {
          console.log('usermessage includes')
          subject = subject.replace('[userMessage]', message)
        }
        if (subject.includes('[userSearch]')) {
          console.log('usersearch includes')
          subject = subject.replace('[userSearch]', searchkeyword)
        }
        if (subject.includes('[ticketId]')) {
          console.log('ticketId includes')
          subject = subject.replace('[ticketId]', timestamp)
        }
      } else {
        subject += subject + ' ' + companyName + ' #' + ticketId;
        // subject = 'Feedback Email Subject'
      }
    }
    let template = `
                <table>
                  <tbody>
                    <tr>
                      <td><b>User Email:</b> ${email}</td>
                    </tr>
                    <tr>
                        <td style="padding-top:25px;"><b style="font-size:17px">Request Info</b></td>
                    </tr>
                    <tr>
                        <td>Browser: ${browser}</td>
                    </tr>
                    <tr>
                        <td>Os: ${os}</td>
                    </tr>
                    <tr>
                        <td>URL: ${URL}</td>
                    </tr>

                    ${helpSteps.map((el, i) => (
                    `<tr>
                            <td style="padding-top:25px;">
                                <b style="font-size:17px">${i + 1}- ${el?.title}</b></br>
                                <div>${el.answer}</div>
                            </td>
                        </tr>`
                      )).join(' ')
                    }

                    ${customInputs.map((el, i) => (
                     `<tr>
                          <td style="padding-top:25px;">
                              <b style="font-size:17px">${el.label}</b>
                          </td>
                      </tr>
                      <tr>
                          <td>
                            ${el.text}
                          </td>
                      </tr>`
                      )).join(' ')
                    }
                    
                    <tr>
                        <td style="padding-top:25px;">
                            <b style="font-size:17px">Tell us more about the request</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                          ${message}
                        </td>
                    </tr>
                </tbody>
              </table>
    `;
    // console.log('to', to)
    // console.log('timestamp', Number(timestamp + String(Math.floor(100 + Math.random() * 900))))
    // console.log('template',template)
    // return
    let payload = {
      from: process.env.VUE_APP_SALESFORCE_CONTACT_EMAIL_TO,
      replyTo:email,
      to: to,
      helpText,
      fileUrl,
      message: {
        subject: subject,
        text: message,
        html: template,
      },
      timestamp: timestamp,
      ticketId: ticketId,
      date: moment().format('L'),
      month: moment().format('MMMM'),
      year: moment().format('YYYY'),
      os: os,
      browser: browser,
      URL: URL,
      support_lib_id: support_lib_id,
      type: 'ticket',
      helpSteps: helpSteps,
      domain_name: snap.data().library_domain,
      companyName: companyName,
      selfManaged: selfManaged,
      customInputs:customInputs
    }
    console.log('payload:-', payload)
    await projectFirestore.collection('mail').add(payload).then((data) => {
      console.log(`Successfully sent email: `, data);
    }).catch((error) => {
      console.error(`Error: `, error);
      return error;
    });
  })


  if (lastArticleId && lastArticleId !== '') {
    projectFirestore.collection('SupportLibraries').doc(support_lib_id).collection('SEARCHES').doc(lastArticleId).get().then(async (doc) => {
      if (doc.exists) {
        console.log(doc.data)
        let obj = doc.data()
        obj.ticket = 1;
        console.log(obj)
        await projectFirestore.collection('SupportLibraries').doc(support_lib_id).collection('SEARCHES').doc(lastArticleId).update(obj)
      } else {
        console.log('doc not exists')
      }
    })
  }
}



