import Vue from 'vue'
import { projectFirestore } from '../firebase/config';
import appendHtml from 'appendhtml';
import { useRoute, } from 'vue-router';
const getIntegrations = () => {
    const isIntegrations = (id, location, tawkChat = undefined) => {
        // console.log('document.location.href',document.location.href)
        let locationUrl = new URL(document.location.href);
        // params.get('ma'); 
        projectFirestore.collection('SupportLibraries').doc(id).get().then(async (doc) => {
            // const hlpt_jquery_loaded = function (callback) { if (window.jQuery) { callback(); } else { setTimeout(function () { hlpt_jquery_loaded(callback) }, 250); } }
            if(locationUrl.searchParams.get('ma')) {
                document.body.setAttribute('chatCodeJsMa', locationUrl.searchParams.get('ma'));
            }
            if (doc.exists) {
                const customScript = (script) => {
                    const container = document.getElementById('integrationScript');
                    appendHtml(script, container);
                }

                if (location == 'widget') {
                    // console.log('doc.data().tawkChatIntegrations', doc.data())
                    if (doc.data()?.tawkChatIntegrations && doc.data()?.tawkChatIntegrations?.enable == true) {
                        // console.log('doc.data().tawkChatIntegrations', doc.data().tawkChatIntegrations)
                        let url = `https://embed.tawk.to/${doc.data().tawkChatIntegrations.propertyId}/${doc.data().tawkChatIntegrations.widgetId}`;
                        let tawkChatScript = `
                        <script type="text/javascript">
                            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                            (function(){
                            var s1=document.createElement("script"),
                            s0=document.getElementsByTagName("script")[0];
                            s1.async=true;
                            s1.src='${url}';
                            s1.charset='UTF-8';
                            s1.setAttribute('crossorigin','*');
                            s0.parentNode.insertBefore(s1,s0);
                            })();
                        </script>`;
                        customScript(tawkChatScript)
                    }

                    if (doc.data().beaconChatIntegration && doc.data().beaconChatIntegration?.enable == true) {
                        let beaconChatScript = `
                        <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
                        </script><script type="text/javascript">window.Beacon('init', '${doc.data().beaconChatIntegration.propertyId}')</script>`;
                        customScript(beaconChatScript)
                    }

                    if (doc.data().zenDeskChatIntegration && doc.data().zenDeskChatIntegration?.enable == true) {
                        let zenDeskChatIntegration = `<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=${doc.data().zenDeskChatIntegration.propertyId}"></script>`;
                        customScript(zenDeskChatIntegration);
                    }

                    if (doc.data().chatIntegrations && doc.data().chatIntegrations?.enable == true) {
                        if (doc.data().library_only == false && doc.data()?.chatIntegrations?.selfManaged == true) {
                            customScript(doc.data().chatIntegrations.widgetScript);
                        } else if (doc.data().library_only == false && doc.data()?.chatIntegrations?.selfManaged == false) {
                            let tag = `<script id="arkChatCode" data-ark=${doc.data().library_refkey} src="/chat_code.js"></script>`;
                            customScript(tag);
                        } else if (doc.data().library_only == true) {
                            customScript(doc.data().chatIntegrations.widgetScript);
                        }
                    }

                    if (doc.data().gistIntegration && doc.data().gistIntegration?.enable == true && doc.data()?.gistIntegration?.workSpaceId !== '') {
                        let script = `<script>
                                (function(d,h,w){var gist=w.gist=w.gist||[];gist.methods=['trackPageView','identify','track','setAppId'];gist.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);gist.push(e);return gist;}};for(var i=0;i<gist.methods.length;i++){var c=gist.methods[i];gist[c]=gist.factory(c)}s=d.createElement('script'),s.src="https://widget.getgist.com",s.async=!0,e=d.getElementsByTagName(h)[0],e.appendChild(s),s.addEventListener('load',function(e){},!1),gist.setAppId("${doc.data().gistIntegration.workSpaceId}"),gist.trackPageView()})(document,'head',window);
                            </script>`
                        customScript(script);
                    }
                    if (doc.data()?.customIntegrations && doc.data()?.customIntegrations?.enable == true) {
                        customScript(doc.data().customIntegrations.widgetScript);
                    }

                    if (doc.data().freshDeskIntegration && doc.data().freshDeskIntegration?.enable == true && doc.data()?.freshDeskIntegration?.widget_id !== '') {
                        let fwSettingsScript = document.createElement('script');
                        fwSettingsScript.type = 'text/javascript';
                        fwSettingsScript.innerHTML = `
                            window.fwSettings={
                                'widget_id': '${doc.data().freshDeskIntegration.widget_id}',
                                'locale': 'en'
                            };
                            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
                        `;
                        document.body.appendChild(fwSettingsScript);
                    
                        // Creating the second script element to load the Freshworks widget script
                        let widgetScript = document.createElement('script');
                        widgetScript.type = 'text/javascript';
                        widgetScript.src = `https://widget.freshworks.com/widgets/${doc.data().freshDeskIntegration.widget_id}.js`;
                        widgetScript.async = true;
                        widgetScript.defer = true;
                        document.body.appendChild(widgetScript);

                        let hideLauncherScript = document.createElement('script');
                        hideLauncherScript.type = 'text/javascript';
                        hideLauncherScript.innerHTML = `window.FreshworksWidget('hide', 'launcher');`;
                        document.body.appendChild(hideLauncherScript);
                    }
                    if (doc.data()?.customIntegrations && doc.data()?.customIntegrations?.enable == true) {
                        customScript(doc.data().customIntegrations.widgetScript);
                    }

                    if (doc.data()?.isGoolgeTranslateEnabled == true) {
                        let integration = `<script type="text/javascript">
                        function googleTranslateElementInit() {
                            new google.translate.TranslateElement({
                                pageLanguage: 'en', 
                                layout: google.translate.TranslateElement.InlineLayout.VERTICAL
                            }, 'modal_gtranslate');
                        }
                        </script>
                    
                        <script type="text/javascript" src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
                    `;
                        customScript(integration);
                        document.body.classList.add('g-translate')
                    }

                    if (doc.data()?.customIntegrations && doc.data()?.customIntegrations?.enable == true) {
                        customScript(doc.data().customIntegrations.widgetScript);
                    }

                }
                if (location == 'theme') {
                    // if (doc.data()?.tawkChatIntegrations && doc.data()?.tawkChatIntegrations?.enable == true) {
                    //     let url = `https://embed.tawk.to/${doc.data().tawkChatIntegrations.propertyId}/${doc.data().tawkChatIntegrations.widgetId}`;
                    //     let tawkChatScript = `
                    //     <script type="text/javascript">
                    //         var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                    //         (function(){
                    //         var s1=document.createElement("script"),
                    //         s0=document.getElementsByTagName("script")[0];
                    //         s1.async=true;
                    //         s1.src='${url}';
                    //         s1.charset='UTF-8';
                    //         s1.setAttribute('crossorigin','*');
                    //         s0.parentNode.insertBefore(s1,s0);
                    //         })();
                    //     </script>`;
                    //     customScript(tawkChatScript)
                    // }

                    // if (doc.data()?.beaconChatIntegration && doc.data()?.beaconChatIntegration?.enable == true) {
                    //     let beaconChatScript = `
                    //     <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
                    //     </script><script type="text/javascript">window.Beacon('init', '${doc.data()?.beaconChatIntegration.propertyId}')</script>`;
                    //     customScript(beaconChatScript)
                    // }

                    if (doc.data().chatIntegrations && doc.data().chatIntegrations?.enable == true) {
                        if (doc.data().library_only == false && doc.data()?.chatIntegrations?.selfManaged == true) {
                            customScript(doc.data().chatIntegrations.bodyScript);
                        } else if (doc.data().library_only == false && doc.data()?.chatIntegrations?.selfManaged == false) {
                            let tag = `<script id="arkChatCode" data-ark=${doc.data().library_refkey} src="/chat_code.js"></script>`;;
                            customScript(tag);
                        } else if (doc.data().library_only == true) {
                            customScript(doc.data().chatIntegrations.bodyScript);
                        }
                    }
                    
                    if (doc.data()?.isGoolgeTranslateEnabled == true) {
                        let integration = `<script type="text/javascript">
                        function googleTranslateElementInit() {
                            new google.translate.TranslateElement({
                                pageLanguage: 'en', 
                                layout: google.translate.TranslateElement.InlineLayout.VERTICAL
                            }, 'modal_gtranslate');
                        }
                        </script>
                    
                        <script type="text/javascript" src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
                    `;
                        customScript(integration);
                        document.body.classList.add('g-translate')
                    }
                    
                    if (doc.data()?.customIntegrations && doc.data()?.customIntegrations?.enable == true) {
                        customScript(doc.data().customIntegrations.bodyScript);
                    }
                }
            }
        })
    }
    return {
        isIntegrations
    }
}

export default getIntegrations

