<template>
    <div class="py-5" v-if="!support_lib_id">
        <Loading ></Loading>
    </div>
    <iframe v-else :src="'https://app.modalsupport.com/AdvancedWidget/'+support_lib_id" class="widget" frameborder="0"></iframe>
</template>
<script>
import getUser from "@/composables/getUser"
import { projectFirestore, timestamp } from '@/firebase/config';
import Loading from "@/components/custom/Loading.vue";
import { ref } from "vue";
    export default {
        name:'Preview',
        components:{
            Loading
        },
        setup() {
            const { user } = getUser();
            const support_lib_id = ref(null)
            projectFirestore.collection('Users').doc(user.value.uid).get().then(async (doc) => {
                if (doc.exists) {
                    support_lib_id.value = doc.data().support_lib_id;
                }
            })
            return {
                support_lib_id
            }
        }
    }
</script>
<style>
    /* .widget {
        width: 100%;
        height: 98vh;
    } */
</style>