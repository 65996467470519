<template>
  <div :class="[isOpen ? ['toast fade show p-2 mt-2', getColor(color)] : 'toast hide']">
    <div class="toast-header bg-transparent border-0">
      <i class="me-2" :class="getIcon(icon)"></i>
      <span class="me-auto font-weight-bold" :class="getTextColor(color)">
        {{ title }}
      </span>
      <small :class="getTextColor(color)">{{ date }}</small>
      <i class="fas fa-times text-md ms-3 cursor-pointer" :class="getTextColor(color)" @click="
        () => {
          $emit('close', false);
        }
      " ></i>
    </div>
    <hr v-if="description" class="horizontal dark m-0" :class="getHrColor(color)" />
    <div v-if="description" class="toast-body" :class="getTextColor(color)">{{ description }}</div>
  </div>
</template>

<script>
export default {
  name: 'ArgonSnackbar',
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    timeOut: {
      type: Number,
      default: 10000
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      color: {
        type: String,
      },
      default: () => ({
        color: 'success',
      }),
    },
    color: {
      type: String,
      default: 'success',
    },
    closeHandler: {
      type: Function,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {};
  },
  methods: {
    getColor: (color) => {
      let colorValue;

      if (color === 'white') {
        colorValue = 'bg-white';
      } else {
        colorValue = `bg-gradient-${color}`;
      }

      return colorValue;
    },
    getIcon(icon) {
      if (icon && typeof icon === 'string') {
        return icon;
      } else if (icon && typeof icon === 'object') {
        return `${icon.component} text-${icon.color}`;
      } else {
        return null;
      }
    },
    getTextColor: (color) => (color === 'white' ? 'text-dark' : 'text-white'),
    getHrColor: (color) => (color === 'white' ? 'dark' : 'light'),
  },
  mounted() {
  },
  watch: {
    isOpen() {
      if (this.isOpen && this.timeOut) {
        setTimeout(() => {
          this.$emit('close', false)
        }, this.timeOut);
      }
    }
  }
}


</script>
<style>
.toast {
  position: fixed;
  z-index: 999;
  right: 15px;
  top: 60px;
}
</style>
