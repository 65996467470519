<template>
  <div class="home-final-step">
    <h3>Final step</h3>
    <form>
      <div>
        <label>Tell us more about your request</label>
        <pre v-if="isSubmit">{{form.request}}</pre>
        <textarea v-else v-model="form.request" placeholder="Type something...."></textarea>
      </div>
      <div class="home-final-step__upload" v-if="stepData?.allowFiles">
        <label>Attach file</label>
        <input ref="fileRef" type="file" class="" @change="handleChangeFile" />
        <button type="button" :disabled="isSubmit" @click="handleUpload">
          <i class="fa fa-upload"></i>
          Browse
        </button>
      </div>
      <div>
        <label>Please provide your email address</label>
        <p v-if="isSubmit">{{form.email}}</p>
        <input v-else v-model="form.email" type="email" placeholder="Enter your email address" />
      </div>
      <template v-if="stepData?.customInputs">
        <div v-for="item, i in stepData.customInputs">
          <label v-html="item.label"></label>
          <p v-if="isSubmit">{{item.text}}</p>
          <template v-else>
            <textarea v-if="item.type == 'longText'" @input="addCustomInput($event.target.value, i)" :placeholder="'Enter your ' + item.label.toLowerCase()"></textarea>
          <input v-else  type="text"   @input="addCustomInput($event.target.value, i)"  :placeholder="'Enter your ' + item.label.toLowerCase()" />
          </template>
        <!-- {{ item }} -->
      </div>
      </template>
      <div v-html="stepData?.thankYouMsg" v-if="isSubmit" class="home-final-step__submit">
        
      </div>
      <div v-else>
        <button
          class="home-final-step__buttons--link"
          type="button"
          @click="$emit('onBack')"
        >
        <i class="fa fa-arrow-left"></i>
          Previous step
        </button>
        <button
          class="home-final-step__buttons--button"
          type="button"
          :disabled="checkDisabled()"
          @click="handleSubmit"
        >
          {{stepData?.submitBtnText}}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'HomeFinalStep',
  props: {
    isSubmit: {
      type: Boolean,
      default: false
    },
    stepData:{
      type:Object,
      default:{}
    }
  },
  emits: ['onSubmit', 'onBack'],
  data() {
    return {
      form: {
        request: '',
        email: '',
        file: '',
        customInputs: []
      },
    };
  },
  mounted(){
    if(this.stepData?.customInputs?.length) {
      this.form.customInputs = [...this.stepData?.customInputs]
      this.form.customInputs.forEach((el ,i) => el.text = '')
    }
  },
  methods: {
    checkDisabled() {
      let check = false;
      if(!this.form.email) {
        check =  true
      } else if(this.form?.customInputs?.length && this.form.customInputs.some((el) => el?.text === '')) {
        check = true
      }
      return check
    },
    handleUpload() {
      if(this.isSubmit) return;
      this.$refs.fileRef.click();
    },
    handleChangeFile(event) {
      this.form.file = event.target.files[0];
    },
    handleSubmit() {
      // console.log('this.form', this.form)
      // return;
      this.$emit('onSubmit', this.form)
    },
    addCustomInput(data, i) {
      this.form.customInputs[i].text = data
    }
  },
};
</script>

<style lang="scss" scoped>
.home-final-step {
  font-family: 'proxima-nova', Arial,sans-serif;
  padding: 2em 0em 2.4em;
  h3 {
    font-size: 1.5rem;
    line-height: 1rem;
    font-weight: 700;
    margin: 0px 0px 30px;
    color: #000;
  }
  form {
    > div {
      margin-bottom: 30px;
    }
  }

  label {
    font-size: 1.1875rem;
    line-height: 1.13684rem;
    margin: 0px 0px 16px;
    font-weight: 600;
    color: #000;
  }
  textarea,
  input {
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 4px 10px;
  }

  textarea {
    min-height: 200px;
    resize: vertical;
  }

  input {
    height: 33px;
    line-height: 25px;
  }

  button {
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 4px;
  }

  p {
    margin: 0;
  }

  pre {
    font-family: 'proxima-nova', Arial,sans-serif;
    padding: 5px;
    width: 100%;
    font-size: 15px;
    background: #f3f3f3;
    color: #666;
    line-height: 1.42857;
    border-radius: 2px;
  }

  .home-final-step__submit {
    border: 1px solid #d7f2d7;
    background: #f6fef6;
    color: #090;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 20px;
}

  .home-final-step__buttons--link {
    color: #999;
    font-size: 0.75rem;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    font-weight: 700;
    border-radius: 0px;
    &:hover {
      color: #000;
      border-color: #000;
    }
  }

  .home-final-step__buttons--button {
    margin-left: 20px;
    background-color: #000000;
    color: #fff;
    font-size: 0.8125rem;
    line-height: 1.47692rem;
    font-weight: bold;
    padding: 4px 20px;
    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.65;
    }

    &:hover {
      background-color: #cccccc;
    }
  }
}

.home-final-step__upload {
  display: flex;
  align-items: center;
  gap: 16px;
  label {
    margin: 0;
  }
  i {
    font-size: 16px;
    position: unset!important;
  }
  button {
    background: #909090;
    color: #fff;
    padding: 6px 20px;
    &:hover {
      background: #333;
    }
    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.65;
    }
  }
  input[type='file'] {
    display: none;
  }
}

</style>
