import { ref } from 'vue'
import { projectAuth, projectFirestore } from '@/firebase/config'
// import { useRouter } from 'vue-router';

// refs
const user = ref(projectAuth.currentUser)


const user_support_lib_id = ref('')

// listen for auth changes outside of function
// so only 1 listener is ever attached
projectAuth.onAuthStateChanged(_user => {
  // const router = useRouter();
  // console.log('User state change. Current user is:', _user)
  if(_user){
    user.value = _user
    projectFirestore.collection("Users").doc(user.value.uid).get().then((doc) => {
      if(doc.exists) {
        user_support_lib_id.value =  doc.data().support_lib_id;
        // console.log('doc.data().support_lib_id;', doc.data().support_lib_id)
      } else {
        user_support_lib_id.value = '';
        // router.push({ name: 'Content' })
        
      }
    })
  } else {
    user.value = '';
  }  
});

const getUser = () => {
  return { user, user_support_lib_id } 
}

export default getUser