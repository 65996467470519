import { projectStorage } from '@/firebase/config'
import { getStorage,  deleteObject } from "firebase/storage";
import moment from 'moment/moment';
import { ref } from "vue"
/* import getUser from "./getUser" */

/* const { user } = getUser() */

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)
  const deleteError = ref(null);

  const uploadImage = async (file, chat) => {
    /* filePath.value = `covers/${user.value.uid}/${file.name}` */
    /* filePath.value = `covers/12345/${moment().valueOf()/file.name}` */

    filePath.value = `covers/12345/${moment().valueOf()}_${file.name}`
    if(chat) {
      filePath.value = `chat/img/${moment().valueOf()}_${file.name}`
    }
    const storageRef = projectStorage.ref(filePath.value)


    try {
      const res = await storageRef.put(file)
      url.value = await res.ref.getDownloadURL()
      console.log("File URL: ", url.value);
    } catch (err) {
      console.log('Upload Error: ', err.message)
      error.value = err
    }
  }

  const deleteImage = async (path) => {  
    const storageRef = projectStorage.ref()
    let pictureRef = projectStorage.refFromURL(path)
    try {
      await pictureRef.delete().then(() => {
        console.log('Image deleted successfully');
        deleteError.value = ''
      }).catch((err) => {
        console.log('error while deleting image ', err)
        deleteError.value = 'error while deleting image'
      })
    } catch (error) {
      console.log('error while deleting image ', error)
      deleteError.value = 'error while deleting image'
    }
  }

  return { uploadImage, deleteImage, deleteError, url, filePath, error }
}

export default useStorage;