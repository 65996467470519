<template>
  <div class="main-container text-center p-0 text-sm footer-hub">
    <div class="coppyrights pb-1"><br />© {{ year }}. All Rights Reserved. App Version: {{ deploymentVersion }}</div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'FooterAdmin',
  setup() {
    const deploymentVersion = process.env.VUE_APP_DEPLOYMENT_VERSION;
    const year = moment().format('YYYY');
    return {
      deploymentVersion,
      year,
    };
  },
};
</script>
<style>
.footer-hub {
  box-shadow: inset 0 8px 26px -4px rgba(20, 20, 20, 0.08) !important;
}
</style>
