import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { useStore } from "vuex";
import { useRoute } from "vue-router";


const updateError = ref(null);
const categoryPositioning = ref([])
const support_lib_id = ref('')

const getUserCategoryPositioning = (path = undefined) => {
    const store = useStore();
    const hlpt_support_library_id = store.state.parent_lib_id;
    const route = useRoute()

    const updateCategoryPositioning = async (categoryArr) => {
        const unique = categoryArr.filter((obj, index) => {
            return index === categoryArr.findIndex(o => obj.id === o.id);
        });

        let categoryPositioningObj = { categoryPositioning: unique };

        // console.log('categoryPositioningObj', unique)
        categoryPositioning.value = unique;
        const parent = route.matched[route.matched.length - 2];
        if (!['Frontend', 'AdvancedWidget', 'Home'].includes(parent?.name)) {

            try {
                await projectFirestore.collection('SupportLibraries').doc(support_lib_id.value).set(categoryPositioningObj, { merge: true });;
            } catch (err) {
                console.error('Error while updating:', err);
                updateError.value = 'Error while updating';
            }
        }
    };

    // Call the function with your data
    // updateCategoryPositioning(yourCategoryArray);


    const createCategoryPositioning = async () => {
        if (hlpt_support_library_id && support_lib_id.value == hlpt_support_library_id) {
            projectFirestore.collection("Categories").where('support_lib_id', '==', support_lib_id.value).orderBy('category_title').get().then(async (snap) => {
                // console.log('snap', snap)
                if (!snap.empty) {
                    const categories = snap.docs.map((doc) => {
                        return { ...doc.data(), id: doc.id, articles: [], subCategory: [] }
                    })
                    await updateCategoryPositioning(categories);
                    return
                }
            })
        } else {
            projectFirestore.collection("Categories").where('support_lib_id', '==', support_lib_id.value).orderBy('category_title').get().then(async (snap) => {
                if (!snap.empty) {
                    const userCategories = snap.docs.map((doc) => {
                        return { category_title: doc.data().category_title, parent_cat_id: doc.data().parent_cat_id, support_lib_id: doc.data().support_lib_id, id: doc.id, articles: [], is_enabled: true, subCategory: [] }
                    })
                    if (hlpt_support_library_id) {
                        await projectFirestore.collection('SupportLibraries').doc(hlpt_support_library_id).get().then(async (doc) => {
                            if (doc.exists) {
                                let defaultCategories = doc.data().categoryPositioning;
                                await projectFirestore.collection('SupportLibraries').doc(support_lib_id.value).get().then(async (userDoc) => {
                                    if (userDoc.exists) {
                                        defaultCategories.forEach((el) => {
                                            if (userDoc.data().disabled_default_categories.includes(el.id)) {
                                                // console.log('includes')
                                                el.is_enabled = false
                                            }
                                        })
                                        let categoryPositioning = [...defaultCategories, ...userCategories]
                                        // console.log('categoryPositioning', defaultDisabledcategories)
                                        await updateCategoryPositioning(categoryPositioning)
                                    }
                                })
                            }
                        })
                    } else {
                        let categoryPositioning = [...userCategories]
                        // console.log('categoryPositioning', defaultDisabledcategories)
                        await updateCategoryPositioning(categoryPositioning)
                    }
                } else {
                    if (hlpt_support_library_id) {
                        await projectFirestore.collection('SupportLibraries').doc(hlpt_support_library_id).get().then(async (doc) => {
                            if (doc.exists) {
                                let defaultCategories = doc.data().categoryPositioning;
                                await projectFirestore.collection('SupportLibraries').doc(support_lib_id.value).get().then(async (userDoc) => {
                                    if (userDoc.exists) {
                                        let categoryPositioning = [...defaultCategories]
                                        // console.log('categoryPositioning', categoryPositioning)
                                        await updateCategoryPositioning(categoryPositioning)
                                    }
                                })
                            }
                        })
                    }
                }
            })
        }
    }

    const getCategoryPositioning = async (lib_id) => {
        categoryPositioning.value = []
        support_lib_id.value = lib_id
        try {
            const doc = await projectFirestore.collection("SupportLibraries").doc(lib_id).get();
            if (doc.exists && doc.data()?.categoryPositioning) {
                if (hlpt_support_library_id && lib_id == hlpt_support_library_id) {
                    categoryPositioning.value = doc.data().categoryPositioning
                    let unsubcribe = projectFirestore.collection('Categories').where('support_lib_id', '==', lib_id).get().then(async (res) => {
                        // unsubcribe()
                        if (!res.empty) {
                            await projectFirestore.collection("SupportLibraries").doc(lib_id).get().then(async (docs) => {
                                let catgeoryPos = docs.data().categoryPositioning.filter((el) => el.support_lib_id == lib_id).map((ele) => ele.id);
                                // console.log()
                                let category = []
                                category = res.docs.map((el) => {
                                    return { ...el.data(), id: el.id }
                                })
                                category.filter((el) => el?.parent_cat_id == null).forEach((elem) => {
                                    if (!catgeoryPos.includes(elem.id)) {
                                        categoryPositioning.value.push({ ...elem, subCategory: [], articles: [] });
                                        // console.log('!not includes')
                                    }
                                })

                                await updateCategoryPositioning(categoryPositioning.value)
                            })
                        }
                    })

                } else if (hlpt_support_library_id) {
                    // console.log('going in else if')
                    categoryPositioning.value = doc.data().categoryPositioning;

                    // Retrieve default categories from the 'SupportLibraries' document
                    projectFirestore.collection('SupportLibraries').doc(hlpt_support_library_id).get().then(async (snap) => {
                        if (snap.exists) {
                            const defaultCats = snap.data().categoryPositioning.filter((el) => el.support_lib_id == hlpt_support_library_id);

                            // Filter custom categories based on 'support_lib_id'
                            const customCatPos = doc.data().categoryPositioning.filter((el) => el.support_lib_id == hlpt_support_library_id);

                            // Filter enabled categories
                            const enabledCategories = snap.data().categoryPositioning.filter((el) => el.is_enabled == true);

                            // Check if new category is added in default categories
                            const idxArr = customCatPos.map((el) => el.id);
                            defaultCats.forEach((el) => {
                                if (!idxArr.includes(el.id)) {
                                    console.log('pushing ')
                                    categoryPositioning.value.push(el);
                                }
                            });

                            // Check if a category is removed from default categories
                            const idxArr2 = defaultCats.map((el) => el.id);
                            customCatPos.forEach((el) => {
                                if (!idxArr2.includes(el.id)) {
                                    const indexToRemove = categoryPositioning.value.findIndex((ele) => ele.id === el.id);
                                    if (indexToRemove !== -1) {
                                        console.log('splicing ')
                                        categoryPositioning.value.splice(indexToRemove, 1);
                                    }
                                }
                            });

                            // Update category names, subcategories, and is_disabled flags
                            defaultCats.forEach((el) => {
                                categoryPositioning.value.forEach((ele) => {
                                    if (el.id === ele.id) {
                                        ele.category_title = el.category_title;
                                        ele.subCategory = el?.subCategory ? el.subCategory : [];
                                        if (el.is_disabled !== undefined) {
                                            ele.is_disabled = el.is_disabled;
                                        }
                                    }
                                });
                            })

                            // Fetch additional categories from Firestore and add them if needed
                            await projectFirestore.collection('Categories').where('support_lib_id', '==', lib_id).get().then(async (res) => {
                                

                                if (!res.empty) {
                                    const docs = await projectFirestore.collection("SupportLibraries").doc(lib_id).get();
                                    const categoryPos = docs.data().categoryPositioning.filter((el) => el.support_lib_id == lib_id).map((ele) => ele.id);

                                    const categories = res.docs.map((el) => {
                                        return { ...el.data(), id: el.id }
                                    });

                                    // Filter root-level categories not in categoryPos and add them
                                    categories.filter((el) => el?.parent_cat_id == null).forEach((elem) => {
                                        if (!categoryPos.includes(elem.id)) {
                                            categoryPositioning.value.push({ ...elem, subCategory: [], articles: [] });
                                        }
                                    });
                                }
                            });


                            updateCategoryPositioning(categoryPositioning.value);
                        }
                    });

                } else {
                    categoryPositioning.value = doc.data().categoryPositioning;
                    // console.log('categoryPositioning.value.length', categoryPositioning.value.length);

                    let unsubscribe = projectFirestore.collection('Categories').where('support_lib_id', '==', lib_id).get().then(async (snapshot) => {
                        // unsubscribe(); // Corrected the typo: unsubscribe() instead of unsubcribe()

                        let defaultCats = [];

                        if (!snapshot.empty) {
                            defaultCats = snapshot.docs.map((el) => {
                                return { id: el.id, ...el.data() };
                            });
                        }

                        // console.log('defaultCats',defaultCats)
                        // Create a map of existing category IDs for efficient lookups
                        const existingIds = new Set(categoryPositioning.value.map((el) => el.id));

                        // Add new categories from defaultCats to categoryPositioning.value
                        defaultCats.forEach((el) => {
                            if (!existingIds.has(el.id)) {
                                categoryPositioning.value.push(el);
                                existingIds.add(el.id);
                            }
                        });

                        // Remove categories that are no longer present in defaultCats
                        const idxArr2 = defaultCats.map((el) => el.id);
                        const elementsToRemove = [];

                        categoryPositioning.value.forEach((el, i) => {
                            if (!idxArr2.includes(el.id)) {
                                // console.log('need to remove');
                                elementsToRemove.push(i);
                            }
                        });

                        // Remove elements in reverse order to avoid index issues
                        for (let i = elementsToRemove.length - 1; i >= 0; i--) {
                            categoryPositioning.value.splice(elementsToRemove[i], 1);
                        }

                        // console.log('categoryPositioning', categoryPositioning.value);

                        // Update category titles, subcategories, and is_disabled flags
                        defaultCats.forEach((el) => {
                            const matchingCategory = categoryPositioning.value.find((ele) => ele.id === el.id);
                            if (matchingCategory) {
                                matchingCategory.category_title = el.category_title;
                                matchingCategory.subCategory = el?.subCategory ? el.subCategory : [];
                                if (el.is_disabled !== undefined) {
                                    matchingCategory.is_disabled = el.is_disabled;
                                }
                            }
                        });

                        // You may want to call `updateCategoryPositioning` here
                        await updateCategoryPositioning(categoryPositioning.value);
                    });

                }
            } else {
                createCategoryPositioning()
            }
        } catch (error) {
            console.error('Error while getting categoryPositioning:', error);
            updateError.value = 'Error while getting categoryPositioning';
        }
    }

    return { getCategoryPositioning, categoryPositioning, updateError }
}

export default getUserCategoryPositioning