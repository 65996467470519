import Fuse from 'fuse.js';

export function calculateRelevance(relevanceArray) {
  let totalRelevance = 0;
  for (let i = 0; i < relevanceArray.length; i++) {
    totalRelevance += relevanceArray[i] * (i + 1);
  }
  return totalRelevance;
}

export function calculateRelevanceArray(queryWords, articleTitle) {
  return queryWords.map(word => (articleTitle.toLowerCase().includes(word.toLowerCase()) ? 1 : 0));
}

export function getSortedSearchResults(searchResultsData, searchQuery) {
  const fuseOptions = {
    keys: ['article_title', 'article_content', 'article_keywords'],
    includeScore: true,
  };
  const fuse = new Fuse(searchResultsData, fuseOptions);
  const searchResults = fuse.search(searchQuery);
  const calculateRelevanceArray = (queryWords, articleTitle) => {
    return queryWords.map(word => (articleTitle.toLowerCase().includes(word.toLowerCase()) ? 1 : 0));
  };
  const sortedResults = searchResults.map(result => {
    const { item, score } = result;
    const relevanceArray = calculateRelevanceArray(searchQuery.toLowerCase().split(' '), item.article_title);
    const totalRelevance = calculateRelevance(relevanceArray);
    return {
      ...item,
      fuzzyScore: score,
      combinedRelevance: totalRelevance + score,
    };
  });
  sortedResults.sort((a, b) => b.combinedRelevance - a.combinedRelevance);
  return sortedResults;
}

// export function getSortedSearchResults(searchResultsData, searchQuery) {
//   return searchResultsData.slice().sort((a, b) => {
//     const queryWords = searchQuery.toLowerCase().split(' ');
//     const relevanceArrayA = calculateRelevanceArray(queryWords, a.article_title);
//     const relevanceArrayB = calculateRelevanceArray(queryWords, b.article_title);
//     const totalRelevanceA = calculateRelevance(relevanceArrayA);
//     const totalRelevanceB = calculateRelevance(relevanceArrayB);
//     return totalRelevanceB - totalRelevanceA;
//   });
// }
