<template>
  <div class="feedback-section">
    <span v-show="answer === ''">Did this answer your question?</span>
    <span v-show="answer === 'yes'">We're glad you could find the answer you were looking for.</span>
    <span v-show="answer === 'no'">
      Sorry you could not find an answer in our knowledge base. Please click Contact Us and fill out the form to submit
      a ticket with our Support Team.
      <button class="btn-feedback" @click="advancedWidget ? $router.push({ name:'HomeAdvancedChat' }) : setViewMode('contactUs')" style="margin: 5px 0px 0px 0px">
        Contact Us
      </button>
    </span>
    <div v-show="answer === ''" class="feedback-buttons">
      <div class="feedback-btn-container">
        <button class="btn-feedback" @click="$emit('answerQuestion', 'yes')">
          <i class="fa fa-thumbs-up thumb-icon" style="color: #fff"></i>
          <span>Yes</span>
        </button>
        <button class="btn-feedback" @click="$emit('answerQuestion', 'no')">
          <i class="fa fa-thumbs-down thumb-icon" style="color: #fff; margin-top: 3px;"></i>
          <span>No</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "HomeFeedback",
    props: {
      answer: {
        type: String,
        default: ''
      },
      setViewMode: {
        type: Function,
        default: () => {}
      },
      answerQuestion: {
        type: Function,
        default: () => {}
      },
      advancedWidget: {
        type: Boolean,
        default: undefined
      }
    },
    emits:['answerQuestion']
  }
</script>