<template>
    <!-- 2hMmOggdTiPggqz2K5Pm -->
    <iframe id="awh-iframe"  class="awh-iframe px-0" :src="showAhw ? iframeSrc : ''" frameborder="0"></iframe>

    
</template>
<script>
// import Api from '../Api';
import Loading from '@/components/custom/Loading.vue';

export default {
    name: 'PreviewAhw',
    props:['showAhw'],
    components:{
        Loading
    },
    data() {
        return {
            isLoading: false,
            baseUrl: 'https://dev.modalsupport.com/', // https://dev.modalsupport.com/
            libid: 'yNoSrTNlC2lrSx4KpTlc', //2hMmOggdTiPggqz2K5Pm // yNoSrTNlC2lrSx4KpTlc
            widgetptions: '?hct=1&hc=1'
        }
    },
    methods: {
        async initAhwContent() {
            this.isLoading = true;
        },
        
    },
    computed: {
        iframeSrc() {
            return `${this.baseUrl}/AdvancedWidget/${this.libid}/dashboard${this.widgetptions}`;
        }
    },
    mounted() {
        this.initAhwContent()
        window.addEventListener(
            'message',
            function (e) {
                if (e.data.event == 'addCompactClass') {
                    document.body.classList.add(`compact-widget`);
                } else if (e.data.event == 'removeCompactClass') {
                    document.body.classList.remove(`compact-widget`);
                }
            },
            false,
        );
    }
}
</script>