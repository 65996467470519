<template>
  <div class="home-help-library">
    <p class="search-help-text">
      {{ dataSource?.title }}
    </p>
    <p class="search-help-sub-text">
      {{ dataSource?.hint }}
    </p>
    <div :class="isRTL ? 'me-md-auto' : 'ms-md-auto mb-3'">
      <div class="input-group input-group-home">
        <input v-model="searchQuery" v-on:keyup.enter="searchArticle()" type="text" class="form-control form-control-home" :placeholder="isRTL ? 'أكتب هنا...' : 'Search our Help Library'" />
        <div class="search-button text-white" @click="searchArticle()">Search</div>
      </div>
    </div>
    <div v-show="currentView === 'search'">
      <div v-show="prevSearchQuery.split(' ').length <= 1" :class="isSearchWarningClosed ? 'search-warning-hidden' : 'search-warning'">
        <div class="warning-close-btn">
          <i class="fa fa-exclamation-triangle"></i>
        </div>
        <div class="warning-text">Using only one keyword will lead to irrelevant results. Add more keywords to get more accurate results.</div>
      </div>
      <Loading v-if="isSearching"></Loading>
      <!-- <span class="d-none">{{ searchResultLength = searchResultsData.length }}</span> -->
      <div v-for="(article, index) in searchResultsData" @click="setArticle(article)" :class="selectedArticle && selectedArticle === article.id ? 'search-result article-content' : 'search-result'">
        <div class="search-result flex align-items-center justify-content-left pt-3 pb-3">
          <i class="fa fa-file-text-o fa-lg ticket-search-document-ico" style="margin: 0px 10px 0px 5px"></i>
          <span v-if="isTranslationLoaded(article.article_title)" v-html="isTranslationLoaded(article.article_title)" :class="selectedArticle && selectedArticle === article.id ? 'selected-article-title ' : ''"></span>
          <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
          <template v-if="selectedArticle === article.id">
            <div class="result-article-content" v-if="isTranslationLoaded(article.article_content)" v-html="isTranslationLoaded(article.article_content)"></div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
          </template>
        </div>
        <hr v-show="index !== searchResultsData.length - 1 && selectedArticle !== article.id" />
      </div>

      <div v-if="!searchResultsData.length" class="no-results">
        {{ dataSource?.messageWhenAnswerNotFound }}
      </div>
      <div class="contact-feedback-section" v-if="selectedArticle || searchResultsData.length == 0">
        <span>Did you find the answer to your question?</span>
        <div class="feedback-buttons">
          <div class="feedback-btn-container contact-feedback-btn-container">
            <button class="btn-feedback contact-btn-feedback" @click="answerQuestion('no')">
              <i class="fa fa-thumbs-down thumb-icon contact-thumb-icon"></i>
              No
            </button>
            <button class="btn-feedback contact-btn-feedback" @click="answerQuestion('yes')">
              <i class="fa fa-thumbs-up thumb-icon contact-thumb-icon" style="margin-bottom: 3px"></i>
              Yes
            </button>
          </div>
        </div>
      </div>
      <div v-show="answer === 'yes'" class="feedback-result">
        <i class="far fa-smile fa-3x"></i>
        <div class="result-text">
          {{ dataSource?.messageWhenAnswerFound }}
        </div>
        <button
          class="next-step mt-2"
          @click="
            setParentView('all');
            $emit('onClear');
          "
        >
          <div>
            Close
            <i class="fa fa-long-arrow-right"></i>
          </div>
        </button>
      </div>
      <div v-show="answer === 'no'" class="feedback-result">
        <i class="far fa-frown fa-3x"></i>
        <div class="result-text failure-text">Thanks for taking a look to see if you could find an answer to your question in the knowledge base! Let's get you on track -</div>
        <button class="next-step mt-3" @click="$emit('onBack', { title: dataSource.title, answer: searchQuery })">
          <div>
            Next step
            <i class="fa fa-long-arrow-right"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import fgetUserAndHlptCategoriesAndArticles from '@/composables/front/fgetUserAndHlptCategoriesAndArticles';
import HomeFeedback from './HomeFeedback.vue';
import moment from 'moment';
import { projectFirestore, timestamp } from '@/firebase/config';
import Loading from '@/components/custom/Loading.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
import getTranslations from '@/composables/getTranslations';
import _ from 'lodash';
export default {
  name: 'HomeHelpLibrary',
  components: {
    HomeFeedback,
    Loading,
  },
  props: {
    setParentView: {
      type: Function,
      default: () => {},
    },
    clearContactSection: {
      type: Function,
      default: () => {},
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  emits: ['onBack', 'onClear'],
  setup() {
    const route = useRoute();
    const store = useStore();
    const library_key = route.params.library_key;
    const { allCategoriesAndArticles, searchArticles, load_articles_and_categories, searchResults, prevSearchQuery } = fgetUserAndHlptCategoriesAndArticles(library_key);
    // load_articles_and_categories();

    const { fetchTranslation, translations } = getTranslations();
    fetchTranslation(library_key);
    console.log('lastArticleId', store.getters.lastFailedArticleSearch?.id);
    return {
      allCategoriesAndArticles,
      load_articles_and_categories,
      searchResults,
      prevSearchQuery,
      searchArticles,
      store,
      library_key,
      translations,
    };
  },
  data() {
    return {
      currentView: 'all',
      selectedArticle: '',
      searchQuery: '',
      isSearching: false,
      isSearchWarningClosed: false,
      answer: '',
      support_lib_id: '',
      successArticleSearchId: '',
      isRTL: false,
      searchResultLength: 0,
      searchResultsData: [],
    };
  },
  methods: {
    setViewMode(view) {
      this.currentView = view;
    },
    setArticle(article) {
      this.selectedArticle = this.selectedArticle === article.id ? '' : article.id;
      this.$store.dispatch('updateLastSuccessArticleSearch', { data: this.selectedArticle });
    },
    async searchArticle() {
      // this.isSearchWarningClosed = false;
      // this.setViewMode('search');
      // this.selectedArticle = '';
      // this.searchResults = [];
      // this.searchResultsData = [];
      // this.isSearching = true;
      // const data = await this.load_articles_and_categories(this.library_key, this.searchQuery);
      // await new Promise(r => setTimeout(r, 2000));
      // try {
      //   this.searchResultsData = _.uniqBy(this.searchResults, 'article_title');
      //   await new Promise(r => setTimeout(r, 1000));
      //   this.isSearching = false;

      //   // this.isSearching = false;
      // } catch (err) {
      //   console.error(err);
      // } finally {
      //   this.setSearches();
      // }

      if (this.searchQuery.trim() !== '') {
          this.isSearching = true
          this.selectedArticle = '';
          this.setViewMode('search');
          this.isSearchWarningClosed = false;
          this.searchResultsData = [];
          await this.searchArticles(this.library_key, this.searchQuery).then((res) => {
            this.searchResultsData = _.uniqBy(res, 'article_title');
            this.isContentLoading = false;
          }).catch((error) => {
            console.error('Error in search:', error);
          }).finally(() => {
            this.isSearching = false;
            this.setSearches()
          });
      }
    },
    makeBold(content) {
      if (!this.prevSearchQuery) return content;
      return content.replace(new RegExp(this.prevSearchQuery, 'gi'), match => {
        return '<strong>' + match + '</strong>';
      });
    },
    closeWarning() {
      this.isSearchWarningClosed = true;
    },
    answerQuestion: async function (answer) {
      this.answer = answer;
      if (this.selectedArticle.id) {
        let article = await projectFirestore.collection('Articles').doc(this.selectedArticle.id).get();
        if (article.data()) {
          let obj = article.data();
          if (answer == 'yes') {
            obj.likes += 1;
            await projectFirestore.collection('Articles').doc(this.selectedArticle.id).update(obj);
          } else {
            obj.disLikes += 1;
            await projectFirestore.collection('Articles').doc(this.selectedArticle.id).update(obj);
          }
        }
      }
    },
    setSearches: async function () {
      let status = '';
      setTimeout(async () => {
        console.log('this.searchResultsData', this.searchResultsData.length);
        this.$store.dispatch('updateSearchedKeyword', this.searchQuery);
        if (this.searchResultsData.length > 0) {
          status = 'passed';
        } else {
          status = 'failed';
        }
        console.log(status);
        let searches = {
          view: 1,
          searchQuery: this.searchQuery,
          status: status,
          timestamp: moment().valueOf(),
          date: moment().format('L'),
          month: moment().format('MMMM'),
          year: moment().format('YYYY'),
          ticket: 0,
          reviewed: false,
          articlesReadId: [],
          articlesReadTitle: [],
        };

        this.support_lib_id = this.$route.params.library_key;
        await projectFirestore
          .collection('SupportLibraries')
          .doc(this.support_lib_id)
          .get()
          .then(async doc => {
            if (doc.exists) {
              console.log('doc exists');
              let res = await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').add(searches);
              this.successArticleSearchId = res.id;
              if (status == 'passed') {
                this.$store.dispatch('updateLastFailedArticleSearch', { data: '' });
              } else {
                this.$store.dispatch('updateLastFailedArticleSearch', { data: res.id });
              }

              const snapshot = await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').get();
              //console.log('snapshot :-', snapshot.docs.map(doc => doc.data()));
            } else {
              console.log('doc not exists');
            }
          });
      }, 1500);
      return;
    },

    isTranslationLoaded(dataElement) {
        if (dataElement) {
            let isReplaced = false;
            
            this.translations.forEach((elem, i) => {
            const searchRegExp = new RegExp(`\\b${elem.source}\\b`, 'gi');
                dataElement = dataElement.replaceAll(searchRegExp, elem.translation);
                if(i ==  this.translations.length - 1) {
                    isReplaced = true;
                }
            });
            
            return isReplaced ? dataElement : null;
        }
    }
  },
  watch: {
    selectedArticle: async function () {
      if (this.selectedArticle !== '') {
        console.log(this.selectedArticle);
        let articlesViews = {
          articleId: this.selectedArticle,
          articleTitle: this.searchResultsData.filter(el => el.id == this.selectedArticle)[0].article_title,
          view: 1,
          timestamp: moment().valueOf(),
          date: moment().format('L'),
          month: moment().format('MMMM'),
          year: moment().format('YYYY'),
        };
        this.support_lib_id = this.$route.params.library_key;
        console.log(this.support_lib_id);
        await projectFirestore
          .collection('SupportLibraries')
          .doc(this.support_lib_id)
          .get()
          .then(async doc => {
            if (doc.exists) {
              console.log('doc exists');
              await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('ARTICLESVIEWS').add(articlesViews);
              await projectFirestore
                .collection('Articles')
                .doc(this.selectedArticle)
                .get()
                .then(async res => {
                  if (res.exists) {
                    let obj = res.data();
                    obj.views += 1;
                    await projectFirestore.collection('Articles').doc(this.selectedArticle).update(obj);
                  }
                });
            } else {
              console.log('doc not exists');
            }
          });

        await projectFirestore
          .collection('SupportLibraries')
          .doc(this.support_lib_id)
          .collection('SEARCHES')
          .doc(this.successArticleSearchId)
          .get()
          .then(async doc => {
            if (doc.exists) {
              let obj = doc.data();
              obj.articlesReadId.push(this.selectedArticle);
              obj.articlesReadTitle.push(this.searchResultsData.filter(el => el.id == this.selectedArticle)[0].article_title);
              await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').doc(this.successArticleSearchId).update(obj);
            }
          });
        console.log('articlesViews', articlesViews);
      }
    },
  },
};
</script>

<style>
.article-content {
  background-color: rgba(248, 248, 248);
  width: 100%;
  padding: 0px 10px;
}

.home-help-library {
  font-family: 'proxima-nova', Arial, sans-serif;
  padding: 2em 0em 2.4em;
}

.contact-feedback-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px;
  background: #ffc;
  margin-top: 40px;
  box-shadow: 0 0 10px #888;
}

.contact-feedback-btn-container {
  margin-top: 10px;
}

.contact-btn-feedback {
  font-weight: bold;
  background: #fff;
  border: 1px solid #eee;
  color: #000;
  padding: 7px 15px;
}

.contact-thumb-icon {
  color: #666;
}

.failure-text {
  margin-right: 100px;
}

.feedback-result {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  margin-top: 40px;
  box-shadow: 0 0 10px #888;
  background-color: #f8f8f8;
  background: #fff;
}

.feedback-result i {
  color: #666;
}

.next-step {
  display: block;
  position: absolute;
  font-size: 0.8rem;
  font-weight: 800;
  padding: 0.5em 0.5em;
  width: auto;
  right: 35px;
  top: 40%;
  left: auto;
  line-height: 12px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #ffffff;
}

.no-results {
  margin-bottom: 35px;
}

.search-help-text {
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0px 0px 16px;
  font-weight: 600;
}

.search-help-sub-text {
  font-size: 15px;
  color: #bbb;
  margin-bottom: 20px;
}

.search-result {
  padding-left: 15px;
  margin-top: 15px;
  cursor: pointer;
  color: rgb(51, 51, 51);
}

.search-result:hover {
  color: #000;
}

.search-result .fa {
  color: #d5d4d4;
}

.search-result:hover .fa {
  color: #000;
}

.search-button {
  font-size: 1rem;
  font-weight: 600;
  color: #595858;
}

.search-warning {
  margin: 10px 0px 5px 0px;
  padding: 1em 3em 1em 1em;
  font-weight: 300;
}

.selected-article-title {
  font-weight: 900;
}

.result-text {
  margin-left: 20px;
  margin-right: 5px;
  padding-right: 80px;
}

.warning-close-btn {
  float: right;
  right: 17px;
  top: 35%;
  position: absolute;
}

.warning-text {
  padding: 0px 5px;
}
</style>
