<template>
    <div class="container wrapper">
        <div class="error-title text-center text-primary">
            Error 404
        </div>
        <div class="error-subtitle text-center mb-2">
            Erm. Page not found
        </div>
        <div class="h5 text-center mb-5">
            We suggest you to go to the homepage while we solve this issue.
        </div>
        <div class="text-center">
            <router-link :to="{ name: 'Content' }"> <button class="btn btn-primary">Go to homepage</button></router-link>
        </div>
    </div>
</template>
<script>
        export default {
            name:'Error404'
        }
</script>

<style>
    .error-title {
        font-size: 72px;
        font-weight: bold;
        margin-top: 20vh;
    }
    .error-subtitle {
        font-size: 32px;
        font-weight: bold;
    }
    /* .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        flex-direction: column;
    } */
</style>