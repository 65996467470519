<template>
    <div>
        <iframe v-if="coBrowsingUrl" :src="coBrowsingUrl" class="w-100" style="height: 100vh;" frameborder="0"></iframe>
    </div>
</template>

<script>
    export default {
        name:'Cobrowsing',
        data() {
            return {
                coBrowsingUrl:''
            }
        },
        mounted() {
            this.coBrowsingUrl = JSON.parse(JSON.stringify(localStorage.getItem('cobrowsing_watch_link')))
            setTimeout(() => {
                localStorage.removeItem('cobrowsing_watch_link')
            }, 1000)
        }
    }
</script>