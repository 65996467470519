<template>
  <div class="home-select-section pt-5">
    <h2 v-if="!!title"><div v-html=title></div></h2>
    <span v-if="!!subTitle"><div v-html=subTitle></div></span>
    <button v-for="(item, index) in dataSource" :key="item" @click="$emit('onSelect', { item })">
      <p v-html=item.answer></p>
    </button>
  </div>
</template>

<script lang="js">
export default {
  name: 'HomeSelectSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    dataSource: {
      type: Array,
      default: () => []
    }
  },
  emits: ['onSelect'],
};
</script>
