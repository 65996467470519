import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getUserInfo = (user_id) => {

  const user_info = ref(null)
  const user_info_error = ref(null)

  const load_user_info = async () => {
    try {
      let res = await projectFirestore.collection("Users").doc(user_id).get();
      // console.log(res)
      // console.log(res.data)

      if (!res.exists) {
        throw error('That user does not exist')
      }
      user_info.value = { ...res.data(), id: res.id }
      
    }
    catch(err) {
      user_info_error.value = err.message
    }
  }
  return { user_info_error, user_info, load_user_info } 
}
export default getUserInfo