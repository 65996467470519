import { ref } from 'vue';
import { projectAuth } from '../firebase/config';
import router from '../router';
import firebase from 'firebase/app'

const error = ref(null)
const createdUser = ref(null)
const createUser = async (email, password) => {
    error.value = null
    await firebase.auth().createUserWithEmailAndPassword(email, password).then((userCredential) => {
        error.value = null;
        createdUser.value = userCredential.user;
    }).catch((err) => {
        console.log(err.message)
        error.value = 'Error while creating user'
    });
}

const createNewUser = () => {
  return { error, createdUser, createUser};
}

export default createNewUser