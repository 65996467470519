<template>
    <div class="bg-light" style="height: 100vh;">
        <Loading v-if="isLoading" class="w-100rounded-0 mx-auto position-relative" />
        <div v-else class="text-center py-5 d-flex align-items-center justify-content-center" style="min-height: 100vh;">
            <div class="card rounded-3 shadow-lg p-4 w-100" style="max-width: 468px;">
                <button
                    class="btn mt-2 p-0 mx-auto rounded-circle text-white d-flex align-items-center justify-content-center"
                    style="height: 75px; width: 75px;" :style="`background: ${themeColor}`">
                    <i class="fa fa-check text-white h2 mb-0"></i>
                </button>
                <div class=" fw-bold text-uppercase lh-sm  fs-1" :style="`color: ${themeColor}`">Thank</div>
                <div class=" fw-bold text-uppercase lh-sm mb-2 fs-1" :style="`color: ${themeColor}`">you!</div>
                <div class="mb-4 px-lg-5 px-md-3 px-2">Thanks for reaching out! We appreciate your feedback</div>
                <!-- <div class="mt-4">
                <button class="btn btn-success mb-1">Back to home</button>
            </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { projectFirestore, timestamp } from '@/firebase/config';
import Loading from "@/components/custom/Loading.vue";
export default {
    name: 'Thankyou',
    props: ['searchQuery'],
    components: {
        Loading
    },
    data() {
        return {
            isLoading: true,
            themeColor:''
        }
    },
    methods: {
        async getThemeColor(library_key) {
            await projectFirestore.collection('SupportLibraries').doc(library_key).get().then((doc) => {
                if (doc.exists) {
                    if (doc.data().publicHelpCenterTheme && doc.data().publicHelpCenterTheme.themeColor) {
                        this.themeColor = doc.data().publicHelpCenterTheme.themeColor
                    } else {
                        this.themeColor = '#2DCE89'
                    }
                }
            })
        }
    },
    async mounted() {
        let library_domain = this.$route.query.a;
        if (library_domain) {
            projectFirestore.collection('SupportLibraries').where('library_domain', '==', library_domain).onSnapshot((res) => {
                let data = []
                if (!res.empty) {
                    this.getThemeColor(res.docs[0].id)
                    data = res.docs.map((el) => {return { id:el.id, ...el.data()}})
                    if (data[0].custom_subDomain) {
                        let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
                        if (!regex.test(data[0].custom_subDomain)) {
                            window.location.href = `https://${data[0].custom_subDomain}/thank-you`
                        } else {
                            window.location.href = `${data[0].custom_subDomain}/thanky-you`
                        }
                    } else {
                        this.isLoading = false
                    }
                } else {
                    this.themeColor = '#2DCE89'
                    this.isLoading = false
                }
            })
        } else {
           await projectFirestore.collection('SupportLibraries').where('custom_subDomain', '==', new URL(window.location.href).hostname).onSnapshot( async(snap) => {
                if(!snap.empty) {
                    this.getThemeColor(snap.docs[0].id)
                }
            })
            this.isLoading = false
        }

    }
}


</script>