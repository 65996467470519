<template>
    <div class="p-2 text-sm">
        {{userData}}
    </div>
</template>    
<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { projectFirestore } from '../firebase/config';

    export default {
        name:'DefaultIntegrations',
        setup() {
            const route = useRoute();
            const librarykey = route.params.id;
            const userData = ref({
                'e':0,
                'support_lib_id':librarykey,
                'library_domain':''
            })
            projectFirestore.collection('SupportLibraries').doc(librarykey).get().then((doc) => {
                if(doc.exists) {
                    userData.value.library_domain = doc.data().library_domain
                    if(doc.data()?.chatIntegrations && doc.data().chatIntegrations.enable == true) {
                        if(doc.data().chatIntegrations.selfManaged == false) {
                            userData.value = { 'e': 1, 'support_lib_id':librarykey} 
                        }
                    }
                } else {
                    userData.value = { 'e': 1, 'support_lib_id':librarykey}
                }
            })
            return {
                userData
            }
        }

    }
</script>