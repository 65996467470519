import moment from "moment";
import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import { useStore } from "vuex";

const getTranslations = () => {
    const translations = ref([])
    const store = useStore()
    const fetchTranslation = (library_key, defaultOnly = undefined) => {
        const hlpt_support_library_id = store.state.parent_lib_id;;
        let categoryTitles = []
        let articlesTitles = []
        let defaultTransaltions = [];
        let customTranslations = [];
        let customDefaultTranslations = [];
        let searchArticlesTitles = [];
        categoryTitles = document.getElementsByClassName('category-title-link');
        articlesTitles = document.getElementsByClassName('category-articles-link');
        searchArticlesTitles = document.getElementsByClassName('search-articles-link');
        
        const fetchDefaultTranslations = () => {
            return new Promise((resolve, reject) => {
                if(hlpt_support_library_id) {
                    projectFirestore.collection('Translations').where('support_lib_id', '==', hlpt_support_library_id).get().then((snap) => { 
                        if (!snap.empty) {
                            customDefaultTranslations = snap.docs.map((el) => el.data())
                        }    
                        resolve()    
                    })
                } else {
                    resolve()
                }
            })
        }
        projectFirestore.collection('SupportLibraries').doc(library_key).get().then((doc) => {
            if (doc.exists) {
                    defaultTransaltions = [
                        { 'source': '__CompanyName', 'translation': doc.data()?.settings?.settings?.companyName ? doc.data()?.settings?.settings?.companyName : '' },
                        { 'source': '__CompanyPhone', 'translation': doc.data()?.settings?.settings?.companyPhone ? doc.data()?.settings?.settings?.companyPhone : '' },
                        { 'source': '__CompanyEmail', 'translation': doc.data()?.settings?.settings?.companyEmail ? doc.data()?.settings?.settings?.companyEmail : '' },
                        { 'source': '__CopyRight', 'translation': doc.data()?.settings?.settings?.siteCopyRight ? doc.data()?.settings?.settings?.siteCopyRight : '' },
                        { 'source': '__Website', 'translation': doc.data()?.settings?.settings?.websiteUrl ? doc.data()?.settings?.settings?.websiteUrl : '' },
                        { 'source': '__WidgetURL', 'translation': doc.data()?.library_domain ? doc.data()?.library_domain : '' },
                        { 'source': '__Logo', 'translation': doc.data()?.publicHelpCenterTheme?.siteIcon ? doc.data()?.publicHelpCenterTheme?.siteIcon : '' },
                        { 'source': '__iOS', 'translation': doc.data()?.settings?.settings?.iosAppUrl ? doc.data()?.settings?.settings?.iosAppUrl : 'https://apps.apple.com/us/app/lead-connector/id1564302502' },
                        { 'source': '__Android', 'translation': doc.data()?.settings?.settings?.androidAppUrl ? doc.data()?.settings?.settings?.androidAppUrl : 'https://play.google.com/store/apps/details?id=com.LeadConnector' },
                        { 'source': '__ZapierAppURL', 'translation': doc.data()?.settings?.settings?.zapierAppUrl ? doc.data()?.settings?.settings?.zapierAppUrl : 'https://zapier.com/apps/leadconnector/integrations' },
                        { 'source': '__AppName', 'translation': doc.data()?.settings?.settings?.appName ? doc.data()?.settings?.settings?.appName : 'LeadConnector' },
                        { 'source': '__MobileAppName', 'translation': doc.data()?.settings?.settings?.appName ? doc.data()?.settings?.settings?.appName : 'LeadConnector' },
                        { 'source': '__ZapierAppName', 'translation': doc.data()?.settings?.settings?.zapierAppName ? doc.data()?.settings?.settings?.zapierAppName : 'LeadConnector' },
                        { 'source': '__year', 'translation': moment().format('YYYY') },
                        { 'source': '__month', 'translation': moment().format('MMMM') },
                        { 'source': '__month_abv', 'translation': moment().format('MMM') },
                        { 'source': '__day_number', 'translation': moment().format('D') },
                        { 'source': '__day_english', 'translation': moment().format('dddd') },
                    ]
            }
            projectFirestore.collection('Translations').where('support_lib_id', '==', library_key).get().then(async(doc) => {
                if (!doc.empty) {
                    customTranslations = doc.docs.map((el) => el.data())
                }


                await fetchDefaultTranslations()
                

                let sorted = []

                    
                if (defaultOnly !== undefined) {
                    sorted = [...defaultTransaltions, ...customDefaultTranslations];
                } else {
                    sorted = [...defaultTransaltions, ...customDefaultTranslations, ...customTranslations];
                }
                translations.value = _.uniqBy(_.reverse(_.clone(sorted)), 'source');
                

            })
        })
    }

    return {
        fetchTranslation,
        translations
    }
}

export default getTranslations